<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 count-screen reports-screen technical-list-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="count-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_count-technical-list-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("count", "reports", "technical-list") }}
        </div>
        <template v-slot:right>
          <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="submitSearch"
        :hasFilters="true"
        :filters="filters"
        routeName="r_count-technical-list-search-filters"
      ></search>
    </template>
    <div v-if="filtersChanged" class="filters-changed-overlay"></div>
    <ul v-if="filtersChanged" class="clebex-item-section search-refresh">
      <li class="clebex-item-section-item no-bottom-border">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("count", "reports", "refresh")
            }}</label>
          </div>
          <span @click="getData" class="follow-up-icon-item">
            <icon icon="#cx-foo1-refresh-28x28" />
          </span>
        </div>
      </li>
    </ul>
    <ul
      v-if="countData && countData.data && countData.data.length"
      class="clebex-item-section technical-list technical-list-header"
      :class="{
        'filters-open': $route.name === 'r_count-technical-list-search-filters'
      }"
    >
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column">
            {{ displayLabelName("count", "reports", "location") }}
          </div>
          <div class="clebex-item-column">
            {{ displayLabelName("count", "reports", "type") }}
          </div>
          <div class="clebex-item-column">
            {{ displayLabelName("count", "reports", "resource-name") }}
          </div>
          <div class="clebex-item-column">
            {{ displayLabelName("count", "reports", "serial-number") }}
          </div>
          <div class="clebex-item-column">
            {{ displayLabelName("count", "reports", "value") }}
          </div>
          <div class="clebex-item-column">
            {{ displayLabelName("count", "reports", "last-pull") }}
          </div>
        </div>
      </li>
    </ul>
    <ul
      v-if="countData && countData.data && countData.data.length"
      class="clebex-item-section technical-list"
      :class="{
        'filters-open': $route.name === 'r_count-technical-list-search-filters'
      }"
    >
      <li
        v-for="item in countData.data"
        :key="item.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column">
            <span>{{ displayLabelName("count", "reports", "location") }}:</span>
            {{ item.location_name }}
          </div>
          <div class="clebex-item-column">
            <span>{{ displayLabelName("count", "reports", "type") }}:</span>
            {{ item.type_name }}
          </div>
          <div class="clebex-item-column">
            <span
              >{{
                displayLabelName("count", "reports", "resource-name")
              }}:</span
            >
            {{ item.resource_name }}
          </div>
          <div class="clebex-item-column">{{ item.serial }}</div>
          <div class="clebex-item-column">
            <span>{{ displayLabelName("count", "reports", "value") }}:</span>
            {{ item.value }}
          </div>
          <div class="clebex-item-column">
            <span
              >{{ displayLabelName("count", "reports", "last-pull") }}:</span
            >
            {{ item.date_begin }}
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <count-footer-menu></count-footer-menu>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import CountFooterMenu from "@/components/count/CountFooterMenu";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "CountTechnicalList",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search")),
    CountFooterMenu
  },
  data() {
    return {
      search: null,
      helpSlug: "count-technical-list"
    };
  },
  created() {
    this.setInitialValues();
    if (this.$route.matched.length > 1) {
      this.search = true;
    }
    if (!this.isMobileDevice()) {
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true
      });
    }
  },
  computed: {
    ...mapState("searchFilters", [
      "filters",
      "filtersChanged",
      "filtersClosed"
    ]),
    ...mapState("count", ["countData"])
  },
  methods: {
    ...mapActions("count", ["getFilters", "calculate"]),
    ...mapActions("searchFilters", ["resetFilters"]),
    toggleSearch() {
      this.search = !this.search;
    },
    modifySearchQuery(searchQuery) {
      this.$store.commit("search/setSearchQuery", searchQuery, {
        root: true
      });
    },
    clearSearchQuery() {
      this.$store.commit("search/setSearchQuery", "", {
        root: true
      });
      this.submitSearch();
    },
    submitSearch() {
      this.calculate({ section: "technical_list" });
    },
    getData() {
      this.calculate({ section: "technical_list" });
      this.$store.commit("searchFilters/setFiltersChanged", false, {
        root: true
      });
    },
    setInitialValues() {
      this.modifySearchQuery("");
      this.calculate({ section: "technical_list", initial: true });
      this.getFilters("technical_list");
    }
  },
  beforeUnmount() {
    this.resetFilters();
    if (!this.isMobileDevice()) {
      this.$store.commit("global/setIsFullWidthScreen", false, {
        root: true
      });
    }
  },
  watch: {
    filtersClosed: {
      handler(val) {
        if (val) {
          if (this.filtersChanged) {
            this.calculate({ section: "technical_list" });
            this.$store.commit("searchFilters/setFiltersChanged", false, {
              root: true
            });
          }
          this.$store.commit("searchFilters/setFiltersClosed", false, {
            root: true
          });
        }
      },
      immediate: true
    }
  }
};
</script>
